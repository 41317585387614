import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService(
  '/api/controltower/takeUpPremiumDiscountApplication',
  api,
  { importLogUrl: '/api/controltower/importLog' }
)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.ccy.options = ctx.ccyOptions
  fieldsById.ukGin.options = ctx.ukGinOptions
}

service.exportItasCsv = async () => {
  try {
    const filter = _.isEmpty(service.lastAppliedFilter)
      ? ''
      : `?${service.lastAppliedFilter}`
    const response = await api.get(
      `/api/controltower/takeUpPremiumDiscountApplication/exportItasCsv${filter}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

export default service
