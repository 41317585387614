<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView } from '@cargill/shared'
import service from '../api/takeUpPremiumDiscountApplicationService'
import FileDownload from 'js-file-download'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  methods: {
    async getMeta() {
      const meta = await service.getMetaWithValidation()
      meta.customToolbarActions = [
        {
          canShow: true,
          titleKey: 'controlTower.misc.exportCsv',
          onClick: this.onExportItasCsvHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-file-csv'
        }
      ]
      return meta
    },
    async onExportItasCsvHandler() {
      const data = await service.exportItasCsv()
      FileDownload(
        new Blob([data], { type: 'text/csv' }),
        `AplicacaoPD_ITAS.csv`
      )
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
